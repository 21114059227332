.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: 2px solid #21bf96 !important;
}

.ant-menu-horizontal > .ant-menu-item-selected a,
.ant-menu-horizontal > .ant-menu-item a:hover {
  color: #21bf96 !important;
}

.ant-modal-content {
  overflow: auto;
  border-radius: 1rem;
  background: #000;
  color: #fff;
  border: 2px solid;
}

.ant-modal-body {
  padding: 0 24px 24px 0;
}

.ant-modal-content .ant-card-head {
  color: #fff;
  font-size: 1.2rem;
}
.ant-modal-content .ant-card {
  background: #000;
  color: #fff;
}
.ant-modal-content .ant-ribbon {
  height: 36px;
  background-color: #000;
  text-transform: uppercase;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  font-weight: 700;
  top: 12px
}

.ant-divider {
  border-top: 1px solid rgb(255 255 255);
}

.ant-alert-info {
  background: #ffffff3d;
  border: none;
}
.ant-alert-message {
  color: #fff;
}

.mainButton {
  cursor: pointer;
  display: inline-flex;
  appearance: none;
  align-items: center;
  -webkit-box-align: center;
  justify-content: center;
  white-space: nowrap;
  vertical-align: middle;
  outline: 2px solid transparent;
  outline-offset: 2px;
  line-height: 1.2;
  font-weight: 600;
  transition: all 2s ease;
  min-height: 3rem;
  min-width: 180px;
  font-size: 1rem;
  background: #e5e5ea;
  background-image: linear-gradient(to left, #0d2130 0%, #9c41ff 101.52%);
  color: #fff;
  width: fit-content;
  text-align: center;
  border-radius: 9999px;
  border-style: none;
}

.mainButton:hover {
  background: #e5e5ea;
  background-image: linear-gradient(to left, #235172 0%, #9c41ff 101.52%);
  color: #fff;
}
.mainButton:focus {
  background: #e5e5ea;
  background-image: linear-gradient(to left, #235172 0%, #9c41ff 101.52%);
  color: #fff;
}
.mainButton:active {
  background: #e5e5ea;
  background-image: linear-gradient(to left, #235172 0%, #9c41ff 101.52%);
  color: #fff;
}

.ant-btn-primary {
  cursor: pointer;
  display: inline-flex;
  appearance: none;
  align-items: center;
  -webkit-box-align: center;
  justify-content: center;
  white-space: nowrap;
  vertical-align: middle;
  outline: 2px solid transparent;
  outline-offset: 2px;
  line-height: 1.2;
  font-weight: 600;
  transition: all 2s ease;
  font-size: 1rem;
  background: #e5e5ea;
  background-image: linear-gradient(to left, #0d2130 0%, #9c41ff 101.52%);
  color: #fff;
  width: fit-content;
  text-align: center;
  border-radius: 24px!important;
  border-style: none;
}

.ant-btn-primary:hover {
  background: #e5e5ea;
  background-image: linear-gradient(to left, #235172 0%, #9c41ff 101.52%);
  color: #fff;
}
.ant-btn-primary:focus {
  background: #e5e5ea;
  background-image: linear-gradient(to left, #235172 0%, #9c41ff 101.52%);
  color: #fff;
}
.ant-btn-primary:active {
  background: #e5e5ea;
  background-image: linear-gradient(to left, #235172 0%, #9c41ff 101.52%);
  color: #fff;
}

.ant-select-selector {
  color: #fff;
  background: transparent;
  background-color: transparent!important;
  white-space: nowrap;
  border: none!important;
  cursor: pointer!important;
  padding-left: 0!important;
}
.ant-select-dropdown {
  background-color: #000;
}
.ant-select-item-option {
  color: #fff;
  font-weight: 700;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #bcbcbc;
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #3a3a3a;
  color: #fff;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
}
.ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button {
  border-radius: 0!important;
}